import './styles/App.scss';
import React from 'react';
import { Routes, Route,  BrowserRouter as Router } from 'react-router-dom';


import Form from './Form.js';
import Head from './Head.js';
import FormPpvenu from './FormPpvenu.js';
import FormPpinscrit from './FormPpinscrit.js';

function App() {
  return (
    <>
      <Head></Head>


<Router>
        <Routes>
            {/*<< Route path="/" element={<Form />} />*/}
          
          {/*<Route path="/:keygen" element={<FormPpvenu />} />*/}
          <Route path="/:keygen" element={<FormPpinscrit />} />
        </Routes>

        </Router>

    </>
  );
}

export default App;
