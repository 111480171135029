import favicon2 from './img/favicon-annee-lumiere-150x150.png';


function App() {
  return (
<>
        <link rel="icon" type="image/png" sizes="16x16" href={favicon2}/>        
        </>
  
      

  );
}

export default App;
